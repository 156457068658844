import {
  Button, Modal, Select, Spin,
} from 'antd';
import { useRouter } from 'next/router';
import { array, bool, func } from 'prop-types';
import {
  useContext, useEffect, useRef, useState,
} from 'react';

import FloatLabel from 'components/base/floatLabel';
import { ChevronSVG } from 'components/iconsSVG/chevron';
import { CloseModalSVG } from 'components/iconsSVG/close';
import { i18nContext } from 'context/i18n';
import {
  COOKIES, getCookie, setCookie,
} from 'utils/helpers';

import { getSlug } from 'actions/slug';
import { useIsMounted, useLocale } from 'hooks';
import { useCountries } from 'hooks/useCountries';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { setGBCookie } from 'utils/i18n';

import styles from './style.module.css';

const I18nModal = ({
  countries, visible, changeVisibility, translateSlug,
}) => {
  const { t, detectedCountry, userInteracted } = useContext(i18nContext);
  const router = useRouter();
  const { language: defaultLang, country: defaultCountry } = useLocale();
  const { isBritishCountry } = useCountries();
  const isMounted = useIsMounted();

  const detectedCountryData = countries.filter((item) => item.id === detectedCountry)[0];
  const defaultCountryData = countries.filter((item) => item.id === defaultCountry)[0];

  // si no tiene datos de ambos países, directamente no mostramos el modal
  const shouldStartWithI18nVisible = useRef(
    detectedCountry !== undefined &&
    (defaultCountry !== detectedCountry) &&
    !isBritishCountry(defaultCountry) &&
    detectedCountryData !== undefined && defaultCountryData !== undefined,
  );

  const [ isVisible, setIsVisible ] = useState(null);
  const [ geoIPLoading, setGeoIPLoading ] = useState(false);

  const getLangsFromCountry = (country) => {
    const foundCountry = countries.filter((item) => item.id === country)[0];

    if (foundCountry === undefined) {
      return [];
    }

    return foundCountry.languages;
  };

  const [ filteredCountries, setFilteredCountries ] = useState(countries);
  const [ filteredLangs, setFilteredLangs ] = useState(getLangsFromCountry(defaultCountry));
  const [ selectedCountry, setSelectedCountry ] = useState(defaultCountry);
  const [ selectedLang, setSelectedLang ] = useState(defaultLang);

  const firstTime = useRef();

  const countryRef = useRef();
  const langRef = useRef();

  const autoLang = filteredLangs?.[0]?.name;
  const autoLang2 = filteredLangs?.[0]?.id;

  const ignoredGeoIpPages = [ 'bolsa', 'compra', 'pedido' ];
  const currentHref = process.browser && window && window.location && window.location.href ? window.location.href : '';
  const shouldIgnoreGeoIpModal = ignoredGeoIpPages.filter((pageName) => currentHref.includes(pageName)).length > 0;

  const handleChangeCountry = (country) => {
    setSelectedCountry(country);
  };

  const handleSearchCountry = (value) => {
    const result = countries.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredCountries(result);
  };

  const handleSearchLang = (value) => {
    const result = getLangsFromCountry(selectedCountry).filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredLangs(result);
  };

  useEffect(() => {
    firstTime.current = true;
  }, []);

  useEffect(() => {
    if (process.browser) {
      const alreadyDetected = getCookie(COOKIES.ALREADY_DETECTED);

      if (detectedCountry !== undefined && alreadyDetected === null && userInteracted) {
        shouldStartWithI18nVisible.current = (defaultCountry !== detectedCountry) && !isBritishCountry(defaultCountry) && detectedCountryData !== undefined;

        if (alreadyDetected === null && shouldStartWithI18nVisible.current && !shouldIgnoreGeoIpModal) {
          changeVisibility(true);
          setIsVisible(true);
        }
      }
    }
  }, [ detectedCountry, userInteracted ]);

  useEffect(() => {
    let visibleValue = visible;

    const alreadyDetected = getCookie(COOKIES.ALREADY_DETECTED);

    if (isVisible === null && alreadyDetected === null && shouldStartWithI18nVisible.current && !shouldIgnoreGeoIpModal) {
      // first time
      visibleValue = shouldStartWithI18nVisible.current;
      changeVisibility(true);
    }

    setIsVisible(visibleValue);
  }, [ visible ]);

  useEffect(() => {
    if (selectedCountry !== undefined) {
      setFilteredLangs(getLangsFromCountry(selectedCountry));
    }

    if (firstTime.current) {
      firstTime.current = false;
    } else {
      setSelectedLang(undefined);
    }
  }, [ selectedCountry ]);

  const handleSubmit = async (countryToSelect, languageToSelect) => {
    setGeoIPLoading(true);
    const countryToUse = countryToSelect || selectedCountry || defaultCountry;
    const languageToUse = languageToSelect || selectedLang || autoLang2;

    const { asPath } = router;

    if (isBritishCountry(selectedCountry)) {
      setGBCookie(selectedCountry);
    }

    dataLayerHandleEvent({
      event: 'eventGA', eventCat: 'Cambio Pais/Idioma', eventAct: `${defaultLang.toUpperCase()}/${defaultCountry.toUpperCase()}`, eventLbl: `${languageToUse?.toUpperCase()}/${countryToUse.toUpperCase()}`,
    });
    let redirect = asPath;
    try {
      if (translateSlug) {
        redirect = await getSlug({ lang: defaultLang, targetLanguage: languageToUse, slug: router.asPath });
      }
      window.location.assign(`${window.location.origin}/${languageToUse}_${countryToUse}${redirect}`);
    } catch (error) {
      window.location.assign(`${window.location.origin}/${languageToUse}_${countryToUse}/`);
    } finally {
      if (isMounted()) {
        setGeoIPLoading(false);
      }
    }
  };

  const handleClose = () => {
    changeVisibility(false);

    setTimeout(() => {
      if (shouldStartWithI18nVisible.current) {
        setCookie(COOKIES.ALREADY_DETECTED, true, 7, COOKIES.DOMAIN);
        shouldStartWithI18nVisible.current = false;
      }
    });
  };

  let modalContent = <>
    <p className={styles.modalTitle}>
      {t('modal.idiomas', 'welcome', 'Welcome')}. <br />
      {t('modal.idiomas', 'choose.country.lang', 'Please choose your country and language.')}
    </p>

    <div className={styles.modalForm}>
      <div className={styles.selectGroup}>
        <Select
          suffixIcon={<ChevronSVG />}
          ref={countryRef}
          placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
          showSearch={true}
          allowClear={selectedCountry !== undefined}
          onSearch={handleSearchCountry}
          filterOption={false}
          onChange={(val) => {
            countryRef.current.blur();
            handleChangeCountry(val);
          }}
          value={selectedCountry}
          className={`${styles.modalSelect} ${styles.noFloatLabel}`}
          virtual={false}
        >
          {filteredCountries.map((country) => (
            <Select.Option key={country.id} className={styles.selectItem}>
              <p>{country.name}</p>
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={styles.selectGroup}>
        <FloatLabel label={t('modal.idiomas', 'select.language', 'Select language')} value={selectedLang === undefined ? autoLang : selectedLang}>
          <Select
            suffixIcon={<ChevronSVG />}
            ref={langRef}
            placeholder={t('modal.idiomas', 'select.language', 'Select language')}
            allowClear={selectedLang !== undefined}
            showSearch={true}
            onSearch={handleSearchLang}
            onChange={(val) => {
              langRef.current.blur();
              setSelectedLang(val);
            }}
            value={selectedLang === undefined ? autoLang : selectedLang}
            className={styles.modalSelect}
            virtual={false}
            notFoundContent={
              <div>
                <p>{t('modal.idiomas', 'no.matches', 'No matches found')}</p>
                <a className={styles.worldWideLink} href="">{t('modal.idiomas', 'visit.ww', 'Visit Camper Worldwide (no eShop)')}</a>
              </div>
            }
          >
            {selectedCountry !== undefined && filteredLangs !== undefined &&
              filteredLangs.map((language) => (
                <Select.Option key={language.id} className={styles.selectItem}>
                  <p>{language.name}</p>
                </Select.Option>
              ))
            }
          </Select>
        </FloatLabel>

      </div>

      <Button onClick={() => handleSubmit()} className={styles.submit}>{t('modal.idiomas', 'submit', 'Submit')}</Button>
    </div>
  </>;

  if (shouldStartWithI18nVisible.current && !shouldIgnoreGeoIpModal && userInteracted) {
    // si shouldStart es true, detectedCountryData y defaultCountryData tienen valor
    const defaultLanguageForDetectedCountry = detectedCountryData.languages.filter((item) => item.default)[0];

    modalContent = <>
      <Spin spinning={geoIPLoading}>
        <p className={styles.modalTitle}>
          {t('modal.idiomas', 'choose.country.geoip', '***It seems that you are not in the selected country.')}
        </p>

        <div className={styles.modalForm}>
          <Button onClick={handleClose} className={`${styles.submit} ${styles.geoIPSubmit}`}>{t('modal.idiomas', 'stay', '***Stay')} {defaultCountryData?.name}</Button>
          <Button loading={geoIPLoading} onClick={() => handleSubmit(detectedCountryData.id, defaultLanguageForDetectedCountry.id)} className={`${styles.submit} ${styles.geoIPSubmit}`}>{t('modal.idiomas', 'go.to', '***Go to')} {detectedCountryData?.name}</Button>
        </div>
      </Spin>
    </>;
  }

  return (
    <Modal
      className={`${styles.i18nModal}`}
      width={'700px'}
      title={null}
      visible={isVisible === true}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      footer={null}
      closeIcon={<CloseModalSVG/>}
      zIndex={1001}
    >
      {modalContent}
    </Modal>
  );
};

I18nModal.propTypes = {
  visible: bool,
  countries: array,
  changeVisibility: func,
  translateSlug: bool,
};

I18nModal.defaultProps = {
  visible: false,
  countries: [],
  changeVisibility: () => true,
  translateSlug: false,
};

export default I18nModal;
