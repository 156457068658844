import {
  string, array, bool, func, object,
} from 'prop-types';
import { useRouter } from 'next/router';
import { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import { Breadcrumb as AntdBreadcrumb } from 'antd';

import { i18nContext } from 'context/i18n';

import { getMarket } from 'utils/helpers';
import { baseTranslations } from 'utils/translations';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { IS_CAMPER, IS_NNORMAL } from 'utils/constants/system';
import styles from './style.module.css';

const fragmentsToOmit = [
  'landings',
  'accessoris',
];

export default function Breadcrumb({
  locale, fragments, shallow, onClick, changeLast, camperlab, i18nKeys, useNextLink, pageName, withLdJson,
}) {
  const { t } = useContext(i18nContext);
  const [ breadcrumbs, setBreadcrumbs ] = useState(null);
  const [ hasScript, setHasScript ] = useState(false);
  const router = useRouter();

  const buildBreadcrumbItem = (item) => {
    if (item.href !== undefined && item.href) {
      return useNextLink ?
        <Link href={item.href}>{item.title}</Link> :
        <a href={item.href}>{item.title}</a>;
    }

    return item.title;
  };

  const createFromFragments = () => {
    const newCrumbs = fragments.map((item, index) => (
      <AntdBreadcrumb.Item key={`bc-${index}`}>
        {buildBreadcrumbItem(item)}
      </AntdBreadcrumb.Item>
    ));
    setBreadcrumbs(newCrumbs);
  };

  useEffect(() => {
    if (window && pageName && pageName === 'grid') {
      const breadCrumbs = [ ...document.getElementsByClassName('ant-breadcrumb-link') ];
      const breadCrumbsText = [];
      if (breadCrumbs) {
        breadCrumbs.forEach((breadcrumb) => {
          if (breadcrumb.innerText) {
            breadCrumbsText.push(breadcrumb.innerText);
          }
        });
      }

      if (breadCrumbsText.length > 1) {
        dataLayerHandleEvent({
          PLPCategoryPath: breadCrumbsText.join('>'),
        });

        dataLayerHandleEvent({

          PLPSearchKeyword: breadCrumbsText[breadCrumbsText.length - 1],

        });
      }
    }
  }, [ breadcrumbs ]);

  useEffect(() => {
    if (window) {
      const breadcrumbsTin = [];
      if (withLdJson && fragments) {
        if (!hasScript) {
          const s = document.createElement('script');
          const link = document.createElement('a');
          link.setAttribute('href', document.location.href);
          const prefix = `${link.protocol}//${link.host}`;
          s.type = 'application/ld+json';
          const proyectName = IS_NNORMAL ? 'Nnormal' : 'Camper';
          const itemListElement = fragments.map((fragment, index) => ({
            'item': `${prefix}${fragment.href}`,
            '@type': 'ListItem',
            'name': index === 0 ? proyectName : fragment.title,
            'position': index + 1,
          }));
          const code = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement,
          };
          s.text = JSON.stringify(code);
          document.getElementsByTagName('head')[0].appendChild(s);
          setHasScript(true);
        }
      }
      if (fragments && fragments.length) {
        createFromFragments();
      } else {
        const { asPath, query } = router;
        const pathSnippets = asPath.split('/').filter((filterItem) => !fragmentsToOmit.includes(filterItem));
        // Elimina el idioma
        pathSnippets.shift();
        const pathNames = pathSnippets.slice();
        const queryKeys = Object.keys(query);
        const market = getMarket(locale);
        let includesTarget = false;
        let includesProduct = false;
        if (!(queryKeys.includes('target') && queryKeys.includes('product'))) {
          Object.keys(baseTranslations.target).forEach((key) => includesTarget = (includesTarget || baseTranslations.target[key].includes(pathSnippets[0])));
          Object.keys(baseTranslations.product).forEach((key) => includesProduct = (includesProduct || baseTranslations.product[key].includes(pathSnippets[1])));
        }
        // Juntar target y product
        if ((queryKeys.includes('target') && queryKeys.includes('product')) || (includesTarget && includesProduct)) {
          pathSnippets[1] = `${pathSnippets[0]}/${pathSnippets[1]}`;
          pathSnippets.shift();
          if (queryKeys.includes('brand') && !i18nKeys.brand) {
            // basicamente para cuando te llega en una url tipo:
            // es_ES/hombre/zapatos/jfdwpjifepiwnfiewpif no te meta ese chorro inventado en el breadcrumbs e intente "traducirlo"
            pathSnippets.pop();
          }
          if (locale === 'th_TH') {
            pathNames[1] = `${i18nKeys.family ? t('menu.lateral', `familia.${i18nKeys.family}`) : pathNames[1]} ${i18nKeys.target ? t('menu.lateral', i18nKeys.target) : pathNames[0]}`;
          } else if (market === 'JP' && i18nKeys.target === '2') {
            pathNames[1] = `${i18nKeys.target ? t('menu.lateral', i18nKeys.target) : pathNames[0]}`;
          } else {
            pathNames[1] = `${i18nKeys.target ? t('menu.lateral', i18nKeys.target) : pathNames[0]} ${i18nKeys.family ? t('menu.lateral', `familia.${i18nKeys.family}`) : pathNames[1]}`;
          }
          pathNames.shift();
        }

        const lastName = pathSnippets.length - 1;

        if (pathNames[lastName]?.includes('?')) {
          pathNames[lastName] = pathNames[lastName].substr(0, pathNames[lastName].indexOf('?'));
        }

        if (pathNames[lastName]?.includes('#')) {
          pathNames[lastName] = pathNames[lastName].substr(0, pathNames[lastName].indexOf('#'));
        }

        if (queryKeys.includes('brand') && i18nKeys.brand) {
          pathNames[1] = i18nKeys.brand;
        }

        const extraBreadcrumbItems = pathSnippets.map((item, index) => {
          if (IS_NNORMAL && item === router.query.brand) {
            return null;
          }
          let name = pathNames[index];
          if (name.includes('_')) {
            name = name.replace(/_/gu, ' ');
          }
          name = decodeURI(name);

          const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
          breadcrumbsTin.push({
            name,
            url,
          });

          if (pathSnippets.length === index + 1) {
            return (
              <AntdBreadcrumb.Item key={url} >
                {name}
              </AntdBreadcrumb.Item>
            );
          }

          return (
            <AntdBreadcrumb.Item key={url} onClick={() => onClick(name)}>
              { useNextLink ?
                <Link shallow={shallow} href={`/${locale}/url`}>{name}</Link> :
                <a href={`/${locale}${url}`}>{name}</a>
              }
            </AntdBreadcrumb.Item>
          );
        });

        if (changeLast && typeof changeLast === 'function') {
          const lastUrl = `/${pathSnippets.slice(0, extraBreadcrumbItems.length).join('/')}`;

          extraBreadcrumbItems[extraBreadcrumbItems.length - 1] =
          <AntdBreadcrumb.Item key={lastUrl} onClick={() => onClick(changeLast())}>
            {changeLast()}
          </AntdBreadcrumb.Item>;
        }

        // CASO ESPECIAL PARA URL IPAD QUE TIENE SKU EN ?OPTION
        if (pageName === 'PDP' && pathSnippets[0].startsWith('producto')) {
          const { option } = query;

          extraBreadcrumbItems.push(
            <AntdBreadcrumb.Item key={'sku-breadcrumb'}>
              {option}
            </AntdBreadcrumb.Item>,
          );
        }

        if (queryKeys.includes('target') && queryKeys.includes('product') && queryKeys.includes('brand') && queryKeys.includes('code')) {
          if (!hasScript) {
            const s = document.createElement('script');
            const link = document.createElement('a');
            link.setAttribute('href', document.location.href);
            const idioma = link.pathname.split('/')[1];
            const prefix = `${link.protocol}//${link.host}/${idioma}`;
            s.type = 'application/ld+json';
            const itemListElement = [
              {
                'item': `${prefix}`,
                '@type': 'ListItem',
                'name': IS_NNORMAL ? 'Nnormal' : 'Camper',
                'position': 1,
              },
              {
                'item': `${prefix}${breadcrumbsTin[0]?.url}`,
                '@type': 'ListItem',
                'name': breadcrumbsTin[0]?.name,
                'position': 2,
              },
            ];
            if (IS_CAMPER) {
              itemListElement.push({
                'item': `${prefix}${breadcrumbsTin[1]?.url}`,
                '@type': 'ListItem',
                'name': breadcrumbsTin[1]?.name,
                'position': 3,
              });
            }
            if (IS_NNORMAL) {
              itemListElement.push({
                'item': `${prefix}${breadcrumbsTin[1]?.url}`,
                '@type': 'ListItem',
                'name': breadcrumbsTin[1]?.name,
                'position': 3,
              });
            }
            const code = {
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement,
            };
            s.text = JSON.stringify(code);
            document.getElementsByTagName('head')[0].appendChild(s);
            setHasScript(true);
          }
        }
        setBreadcrumbs(extraBreadcrumbItems);
      }
    }
  }, [ router, fragments ]);

  return (
    <AntdBreadcrumb separator={false} className={styles.breadcrumbWrapper}>
      {
        fragments.length === 0 && <AntdBreadcrumb.Item>
          { useNextLink ?
            <Link href={!camperlab ? `/${locale}` : `${locale}/content/camperlab`}>{!camperlab ? t('generico', 'breadcrumb.home', '**Home') : t('generico', 'breadcrumb.camperlab', '**Camperlab')}</Link> :
            <a href={`/${locale}${!camperlab ? '/' : '/content/camperlab'}`}>{!camperlab ? t('generico', 'breadcrumb.home', '**Home') : t('generico', 'breadcrumb.camperlab', '**Camperlab')}</a>
          }
        </AntdBreadcrumb.Item>
      }
      {breadcrumbs}
    </AntdBreadcrumb>
  );
}

Breadcrumb.propTypes = {
  locale: string,
  fragments: array,
  shallow: bool,
  onClick: func,
  changeLast: func,
  camperlab: bool,
  i18nKeys: object,
  useNextLink: bool,
  pageName: string,
  withLdJson: bool,
};

Breadcrumb.defaultProps = {
  fragments: [],
  shallow: true,
  camperlab: false,
  i18nKeys: {},
  onClick: () => true,
  useNextLink: false,
  pageName: null,
  withLdJson: false,
};
