import { handleError } from 'utils/apiWrapper';
import { TRANSLATE_SLUG } from 'utils/routes';

export async function getSlug({ lang, targetLanguage, slug }) {
  try {
    const query = new URLSearchParams({ lang, targetLanguage, slug });
    const r = await fetch(`${TRANSLATE_SLUG}?${query.toString()}`, { method: 'GET' });
    const response = await r.json();
    if (!r.ok || !response.slug) {
      throw new Error(`Error getting slug ${slug} in language ${targetLanguage}`);
    }
    return response.slug;
  } catch (error) {
    handleError(error);
    throw error;
  }
}
