export const PowerSVG = (props) => (
  <svg
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.61A7.334 7.334 0 116 1.61v1.337C3.67 3.771 2 5.994 2 8.607a6 6 0 108-5.66zM8.668 0v8H7.333V0z"
      fillRule="evenodd"
    />
  </svg>
);
