import { useContext } from 'react';
import { Modal } from 'antd';

import { i18nContext } from 'context/i18n';
import { CloseModalSVG } from 'components/iconsSVG/close';
import styles from './style.module.css';

const ProtectionDataModal = ({ visible, onCancel, origin = 'checkout' }) => {
  const { t, profileData } = useContext(i18nContext);

  return (
    <Modal width={'400px'} visible={visible} footer={null} onCancel={onCancel} closeIcon={<CloseModalSVG />} className={styles.protectionData}>
      <h2>{t('menu.derecha', 'informacion.proteccion.datos')}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: t('menu.derecha', `proteccion.datos.info.${origin}`).replace('<TAG privacy_policy_url>', profileData?.urlPrivacyPolicy),
        }}
      />
    </Modal>
  );
};

// ProtectionDataModal.propTypes = {
//   visible: bool,
//   onCancel: func,
//   origin: string,
// };

export default ProtectionDataModal;
