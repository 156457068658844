export const SadFaceSVG = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 40.0011C64 53.2567 53.2537 64 40 64C26.7463 64 16 53.2567 16 40.0011C16 26.7454 26.7463 16 40 16C53.2537 16 64 26.7454 64 40.0011Z"
      stroke="#1D1D1B"
      strokeWidth={1.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33 33C33 34.1045 32.328 35 31.5 35C30.672 35 30 34.1045 30 33C30 31.8955 30.672 31 31.5 31C32.328 31 33 31.8955 33 33Z"
      fill="#1D1D1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 33C50 34.1045 49.328 35 48.5 35C47.672 35 47 34.1045 47 33C47 31.8955 47.672 31 48.5 31C49.328 31 50 31.8955 50 33Z"
      fill="#1D1D1B"
    />
    <path
      d="M28 53C28 46.3735 33.3721 41 40 41C46.6303 41 52 46.3735 52 53"
      stroke="#1D1D1B"
      strokeWidth={1.8}
    />
  </svg>
);
