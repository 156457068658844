import { useContext, useEffect, useRef } from 'react';

import { i18nContext } from 'context/i18n';
import { CloseMdSVG } from 'components/iconsSVG/close';
import { getMarket } from 'utils/helpers';
import { IS_NNORMAL, IS_CAMPER } from 'utils/constants/system';
import { hasToRenderGiftHat } from 'utils/nnormal';

import { GIFT_BAG_BASE } from 'utils/constants/products';

import styles from './style.module.css';

const imgCustom = {
  JP: (
    <div className={styles.photoCalendar}>
      <picture>
        <img src={'/assets-new/xmas22-jp.png'} alt={'Image of gift Bag photo'} width={'100%'} height={'100%'} />
      </picture>
    </div>
  ),
  KR: (
    <div className={styles.photo}>
      <picture>
        <img src={'/assets-new/BAG_MAIN_LF.jpg'} alt={'Image of gift Bag photo'} width={'100%'} height={'100%'} />
      </picture>
    </div>
  ),
  TH: (
    <div className={styles.photo}>
      <picture>
        <img src={'/assets-new/gwp_th.png'} alt={'Image of gift Bag photo'} width={'100%'} height={'100%'} />
      </picture>
    </div>
  ),
};

const customGiftBagImages = ['TH']; // hasta que no llegue de api o algo cambiemos esta array y au

export function GiftBagModal({ visible = false, onClose = () => false }) {
  const { t, locale, profileData } = useContext(i18nContext);
  const { config } = profileData;
  const contentRef = useRef(null);
  const market = getMarket(locale);

  useEffect(() => {
    const modalElement = document.querySelector('#modal-gift-mask');
    const modalContentElement = document.querySelector('#modal-gift-content');
    if (modalElement && modalContentElement) {
      setTimeout(() => {
        modalElement.style.opacity = visible ? 1 : 0;
        modalContentElement.style.opacity = visible ? 1 : 0;
      }, 1);
    }
    const handleClickOutside = (event) => {
      const eventTargetIsTriggerer =
        event.target &&
        event.target.parentElement &&
        event.target.parentElement.onclick &&
        event.target.parentElement.onclick.toString().includes('showGiftBagPopUp');
      if (contentRef.current && !contentRef.current.contains(event.target) && !eventTargetIsTriggerer) {
        onClose();
      }
    };
    if (visible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [visible]);

  const renderGifHat = hasToRenderGiftHat(t, market);
  return (
    <div>
      <div
        className={`${styles.modalGiftBagMask} ${visible ? styles.modalGiftBagMaskVisible : ''}`}
        id="modal-gift-mask"
        // onCancel={onClose}
      />
      <div>
        <div ref={contentRef} id="modal-gift-content" className={`${styles.modalContent} ${visible ? styles.modalContentVisible : ''} `}>
          {!IS_NNORMAL && customGiftBagImages.includes(market) && imgCustom[market] !== null && imgCustom[market]}
          {IS_NNORMAL && renderGifHat && (
            <div className={styles.photo}>
              <picture>
                <img
                  src="https://cloud.nnormal.com/is/image/JGVzaG9wMDNtYmlnZ3JleSQ=/N2AHC01-003_LF.jpg"
                  alt={'Image of gift Bag photo'}
                  width={'100%'}
                  height={'100%'}
                  className="object-cover"
                />
              </picture>
            </div>
          )}
          {IS_CAMPER && config?.sku_gift_purchase && (
            <div className={styles.photo}>
              <picture>
                <img src={`${GIFT_BAG_BASE}/${config.sku_gift_purchase}.jpg`} alt={'Image of gift Bag photo'} width={'100%'} height={'100%'} />
              </picture>
            </div>
          )}
          <div className={`${styles.content} ${IS_NNORMAL ? styles.Nnormal : styles[market]}`}>
            <span onClick={onClose} className={styles.closeSvg}>
              <CloseMdSVG />
            </span>
            <p className={styles.title}>{t('ficha.producto', 'bundle.title')}</p>
            <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: t('ficha.producto', `bundle.text.${market}`) }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftBagModal;
