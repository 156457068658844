export const AccountFaceOutlineSVG = (props) => (
  <svg height="34" viewBox="0 0 34 34" width="34" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      transform="translate(1 1)"
    >
      <path
        d="m32 16.0007049c0 8.8371151-7.1641791 15.9992951-16 15.9992951s-16-7.16218-16-15.9992951c0-8.837115 7.1641791-16.0007049 16-16.0007049s16 7.1635899 16 16.0007049z"
        stroke="#1d1d1b"
      />
      <path
        d="m12.3181818 11.7318182c0 .8560082-.5152 1.55-1.15 1.55s-1.15-.6939918-1.15-1.55.5152-1.55 1.15-1.55 1.15.6939918 1.15 1.55"
        fill="#1d1d1b"
      />
      <path
        d="m22.3924116 11.7318182c0 .8560082-.5152 1.55-1.15 1.55s-1.15-.6939918-1.15-1.55.5152-1.55 1.15-1.55 1.15.6939918 1.15 1.55"
        fill="#1d1d1b"
      />
      <path
        d="m24.2727273 16.4c0 4.5281357-3.5814142 8.2-8 8.2-4.420202 0-8.00000003-3.6718643-8.00000003-8.2"
        stroke="#1d1d1b"
      />
    </g>
  </svg>
);
