import { getRealLanguage } from 'utils/helpers';

const DEFAULT_LOCALE_CONFIG = 'ES';

export function getConfigByLocale(locale, localeConfigs) {
  const [ lang, country ] = locale.split('_');
  const dateLocales = Object.keys(localeConfigs);
  const dateLocale = dateLocales.includes(country) ? country : DEFAULT_LOCALE_CONFIG;
  const langToSend = getRealLanguage(lang);

  const config = localeConfigs[dateLocale](`${langToSend}_${country}`)[0];
  return config;
}
