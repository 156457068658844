/**
 * Abandoned Cart.
 * The task is to show an abandoned cart banner when certain conditions are met
 * To show the banner, we need to make sure we are on mobile, that we are not in certain pages and that we have items in the cart.
 * I also added an event listener on the visibilitychange event, so we evaluate on window focus wether to show the banner.
 * When the banner is closed we need to persist to sessionStorage the decision to hide the banner so we don't show it again on the same session
 */

import { useI18n } from 'context/i18n';
import { useIsMobile } from 'hooks';
import { useEffect, useState } from 'react';
import { CloseModalSVG } from 'components/iconsSVG/close';
// There is an error importing from the default export because it is a esm module. This way we don't have the error but we also don't have the types imported
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { isBagAbandoned } from 'utils/bag';
import { IS_NNORMAL } from 'utils/constants/system';

import { dataLayerHandleEvent } from 'utils/dataLayers';

import styles from './style.module.css';

const pagesWhereBannerIsHidden = [ '/bolsa', '/compra', '/pedido' ];

function AbandonedCartBanner() {
  if (IS_NNORMAL) return null;
  const router = useRouter();
  const { bagLength, t, locale } = useI18n();
  const [ show, setShow ] = useState(false);
  const [ closed, setClosed ] = useState(() => {
    if (typeof window === 'undefined') return false;
    const isBagAbandonedBannerClosed = window.sessionStorage.getItem('isBagAbandonedBannerClosed');
    if (!isBagAbandonedBannerClosed) return false;
    return true;
  });
  const isMobile = useIsMobile();
  const visible = isMobile && show;

  useEffect(() => {
    const checkIfShow = () => {
      if (closed || show || document.hidden) return;
      // should not be shown in certain pages
      // check if the difference between bag creation date and now is highter than threshold
      if (bagLength > 0 && !pagesWhereBannerIsHidden.includes(router.route) && isBagAbandoned()) {
        setShow(true);
      }
    };
    checkIfShow();
    document.addEventListener('visibilitychange', checkIfShow);
    return () => {
      document.removeEventListener('visibilitychange', checkIfShow);
    };
    // The bagLength is not calculated on the first render so we need to add it to the dependency array to make the effect run again
  }, [ router.route, bagLength ]);

  const handleHideBanner = () => {
    setShow(false);
    setClosed(true);
    window.sessionStorage.setItem('isBagAbandonedBannerClosed', 'true');
  };

  const cartClick = () => {
    try {
      dataLayerHandleEvent({
        event: 'notification_cart_abandonment',
        event_category: 'notification',
        event_detail_1: '',
        event_detail_2: '',
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AnimatePresence >
      {visible && (
        <motion.div initial={{ translateY: '-100%' }} animate={{ translateY: 0, transition: { type: 'tween', ease: 'easeInOut' } }} exit={{ translateY: '-100%', transition: { type: 'tween', ease: 'easeInOut' } }} className={styles.container}>
          <div className={styles.icon}>
            <span>{String(bagLength)}</span>
          </div>
          <a onClick={cartClick} className={styles.text} href={`/${locale}/bolsa`}>
            {t('bolsa', 'carrito.abandonado', 'Tienes algo en tu carrito de tu última visita, continúa tu compra')}</a>
          <div className={styles.close}>
            <button className={styles.close} onClick={handleHideBanner}>
              <CloseModalSVG />
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

AbandonedCartBanner.displayName = 'AbandonedCartBanner';

export default AbandonedCartBanner;
