import { useEffect, useRef, useState } from 'react';

export function useCopyToClipboard(text) {
  const [state, setState] = useState('idle'); // idle | copied
  const timer = useRef(null);

  // si desmontamos el componente, limpiamos el timer
  useEffect(
    () => () => {
      clearTimeout(timer.current);
      return () => {
        clearTimeout(timer.current);
      };
    },
    [],
  );

  const onCopy = () => {
    navigator.clipboard.writeText(text ?? '');
    setState('copied');
    timer.current = setTimeout(() => {
      setState('idle');
    }, 2000);
  };

  return { onCopy, state };
}
