import { useEffect, useState, useRef } from 'react';

export default function useDelayedState(initialState) {
  const [ state, setState ] = useState(initialState);
  const timeoutRef = useRef();

  const setStateAfter = (newState, delay) => {
    clearTimeout(timeoutRef.current);
    if (delay === 0 || delay === undefined) {
      setState(newState);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setState(newState);
        timeoutRef.current = null;
      }, delay);
    }
  };

  useEffect(() => () => {
    if (timeoutRef.current) { clearTimeout(timeoutRef.current); }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return [ state, setStateAfter ];
}
